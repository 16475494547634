import { difference, every, filter, find, flatten, get, identity, includes, isEmpty, map, property, reject, uniq, values } from 'lodash'
import { createSelector, createSlice } from '@reduxjs/toolkit'

import { getBoardByName, getUARTPinsForBoard } from 'slices/boards'
import { usernameSelector } from 'selectors/users'
import GroupActions from 'actions/groups'
import { notifyConnected, notifyDisconnected } from 'components/WipperSnapper/connection_notifiers'


const { reducer, actions } = createSlice({
  name: 'devices',

  initialState: {
    connections: [],
    connectionHistory: {}
  },

  reducers: {
    setConnections: (state, { payload: connections }) => ({ ...state, connections }),
    setConnectionHistory: (state, { payload: connectionHistory }) => ({ ...state, connectionHistory }),
  },
})

export const
  // ACTIONS
  refreshDevice = deviceKey => (dispatch, getState) => {
    const
      state = getState(),
      username = usernameSelector(state)

    return dispatch(GroupActions.get({ username, group_key: deviceKey }))
  },

  refreshCurrentDevice = () => (dispatch, getState) => {
    const
      state = getState(),
      device = selectCurrentDevice(state)

    return device ? dispatch(refreshDevice(device.key)) : Promise.resolve()
  },

  clearCurrentDevice = () => dispatch => dispatch(GroupActions.groupsClearCurrent()),

  updateDeviceConnections = (presentConnections, connectionHistory) => (dispatch, getState) => {
    // analyze connection changes
    const
      oldConnections = selectConnections(getState()),
      justConnected = difference(presentConnections, oldConnections),
      justDisconnected = difference(oldConnections, presentConnections)

    // notify the connections and disconnections
    justConnected.forEach(key => notifyConnected(key))
    justDisconnected.forEach(key => notifyDisconnected(key))

    // call through to set the state
    dispatch(actions.setConnections(presentConnections))

    if(connectionHistory) {
      // if we're given a history (on browser connect), set it
      dispatch(actions.setConnectionHistory(connectionHistory))

    } else {
      // if not given a history (on device connect/disconnect),
      // update our history from the connection changes
      const timestamp = Date.now()

      justConnected.forEach(deviceId => dispatch(addConnectionEvent(deviceId, 'connected', timestamp)))
      justDisconnected.forEach(deviceId => dispatch(addConnectionEvent(deviceId, 'disconnected', timestamp)))
    }
  },

  addConnectionEvent = (deviceId, eventName, timestamp) => (dispatch, getState) => {
    const history = { ...selectConnectionHistory(getState()) } // copy existing history

    if(!history[deviceId]) {
      // start a history for this device if not present
      history[deviceId] = { connected: [], disconnected: [] }

    } else {
      // otherwise copy itself
      history[deviceId] = { ...history[deviceId] }
    }
    // add the timestamp to the appropriate device/event, copy and re-assigning the array
    history[deviceId][eventName] = history[deviceId][eventName].concat(timestamp)

    // overwrite the state with our copied-and-updated state
    dispatch(actions.setConnectionHistory(history))
  },

  // SELECTORS
  devicesState = property("devices"),

  // Groups with a machine_name are actually wippers
  selectDevices = state => filter(get(state, "groups.groups", []), "machine_name"),

  selectConnections = createSelector([devicesState], property('connections')),

  selectConnectionHistory = createSelector([devicesState], property('connectionHistory')),

  selectCurrentDevice = state => {
    const currentGroup = get(state, "groups.group", {})

    return currentGroup.machine_name ? currentGroup : null
  },

  selectCurrentDeviceBoardName = createSelector(
    [selectCurrentDevice], property("machine_name")
  ),

  selectCurrentDeviceBoard = createSelector(
    [identity, selectCurrentDeviceBoardName], getBoardByName
  ),

  selectCurrentDeviceBoardMagic = createSelector(
    [selectCurrentDeviceBoard], property("magic")
  ),

  selectCurrentDeviceSemver = createSelector(
    [selectCurrentDevice], property("wipper_semver")
  ),

  selectCurrentDeviceComponents = createSelector([selectCurrentDevice], device => device?.feeds || []),

  selectCurrentDeviceBuiltInComponentTypes = createSelector(
    [selectCurrentDeviceBoardMagic], magic => {
      return magic?.components?.length
        ? uniq(map(magic.components, ({ type }) => type.split(':')[0]))
        : []
  }),

  selectCurrentDeviceOnline = createSelector(
    [identity, selectCurrentDevice],
    (state, device) => isDeviceOnline(state, device?.key)
  ),

  // Returns the analog and digital pin collections from the Board definition
  // with a "used" boolean added that reflects whether the pin already has
  // a wipper component connected to it
  selectCurrentDevicePinStatus = createSelector(
    [selectCurrentDeviceBoard, selectCurrentDeviceComponents],
    (board, components) => {
      if(!board) { return { analog: [], digital: [], pwm: [], servo: [] }}

      const
        { digitalPins, analogPins } = board.components,
        usedPinNames = flatten([
          map(components, "wipper_pin_info.pinName"),
          map(components, "wipper_pin_info.dataPinName"),
          map(components, "wipper_pin_info.clockPinName"),
          map(components, "wipper_pin_info.uartTx"),
          map(components, "wipper_pin_info.uartRx"),
        ]),
        addUsedMapper = collection => map(collection, pin => ({
          ...pin,
          used: includes(usedPinNames, pin.name)
        })),
        digital = addUsedMapper(digitalPins),
        analog = addUsedMapper(analogPins),
        allPins = digital.concat(analog),
        pwm = filter(allPins, 'hasPWM'),
        servo = filter(allPins, 'hasServo')

      return { analog, digital, pwm, servo }
    }
  ),

  selectCurrentDeviceAvailablePins = createSelector([selectCurrentDevicePinStatus], currentPins => ({
    analog: reject(currentPins.analog, "used"),
    digital: reject(currentPins.digital, "used"),
  })),

  selectCurrentDeviceI2CSettings = createSelector(
    [selectCurrentDeviceBoard], board => board?.components?.i2cPorts?.[0]
  ),

  selectCurrentDeviceSupportsI2C = createSelector(
    [selectCurrentDeviceI2CSettings], i2cSettings => !isEmpty(i2cSettings)
  ),

  selectCurrentDeviceIsI2CReady = createSelector(
    [selectCurrentDeviceOnline, selectCurrentDeviceSupportsI2C],
    (connected, supported) => (connected && supported)
  ),

  selectCurrentDeviceUARTPins = createSelector(
    [identity, selectCurrentDeviceBoardName], getUARTPinsForBoard
  ),

  // QUERIES

  getDeviceByKey = (state, key) => find(selectDevices(state), { key }),

  hasDeviceByKey = (state, key) => !!getDeviceByKey(state, key),

  isDeviceOnline = (state, deviceKey) => includes(selectConnections(state), deviceKey),

  deviceConnectionHistory = (state, deviceKey) => {
    const history = selectConnectionHistory(state)[deviceKey]

    if(!history) { return [] }

    const
      { connected=[], disconnected=[] } = history,
      // convert them to [event,time] arrays
      connectedEvents = map(connected, ts => ['connected', ts]),
      disconnectedEvents = map(disconnected, ts => ['disconnected', ts]),
      // combine, sorted by timestamp
      combinedEvents = connectedEvents.concat(disconnectedEvents).sort((a, b) => b[1] - a[1])

    return combinedEvents
    // Looks like:
    // [
    //   ['connected', 1730919896811],
    //   ['disconnected', 1730919796811],
    //   ['connected', 1730919696811],
    //   ['disconnected', 1730919596811],
    //   ['connected', 1730919496811],
    //   ['disconnected', 1730919396811],
    //   ['connected', 1730919296811],
    // ]
  },

  getComponentTypeAvailability = (state, componentType) => {
    let available = true
    const messages = []

    // I2C not ready
    if(componentType.isI2C && !selectCurrentDeviceSupportsI2C(state)) {
      available = false
      messages.push({
        short: "I2C Unsupported",
        long: "This device does not support I2C components."
      })
    }

    if(componentType.isUART) {
      if(find(selectCurrentDeviceComponents(state), { wipper_pin_info: { isUART: true } })) {
        // already have UART component
        available = false
        messages.push({
          short: "Limit 1 UART Component",
          long: "This device already has a UART component configured. Remove it to add another."
        })
      }

      const uartPins = selectCurrentDeviceUARTPins(state)
      if(!uartPins) {
        // no UART pins specified
        available = false
        messages.push({
          short: "UART Unsupported",
          long: "This device does not support UART components."
        })

      } else {
        const
          { analog, digital } = selectCurrentDeviceAvailablePins(state),
          allPins = digital.concat(analog),
          availablePinNames = map(reject(allPins, "used"), "name"),
          uartPinNames = values(uartPins),
          uartAvailable = every(uartPinNames, uartPin => includes(availablePinNames, uartPin))

        if(!uartAvailable) {
          // UART pins in use
          available = false
          messages.push({
            short: "UART Pins In Use",
            long: "This device's UART pins are already in use by another component."
          })
        }
      }
    }

    return { available, messages }
  },

  hasBuiltInComponentType = (state, componentType) => {
    const componentTypes = selectCurrentDeviceBuiltInComponentTypes(state)
    return includes(componentTypes, componentType)
  }

export default reducer
